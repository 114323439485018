<template>
  <div class="parter-logo">
    <img class="parter-img" v-for="(item, index) in imgList" :key="index" :src="item.url" />
  </div>
</template>

<script>
import UtilsPath from '../../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      imgList: []
    }
  },
  async mounted () {
    let temp = await axios.get(UtilsPath.getSignatureInfo)
    this.$data.imgList = temp.data
  }
}
</script>

<style scoped>
.parter-logo {
  margin-top: 62px;
  padding: 0 200px 0 200px;
  margin-bottom: 120px;
}
.parter-img{
  width:440px;
  height:210px;
  margin-right:34px;
  margin-top:20px
}
</style>
